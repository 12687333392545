import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './navbar.css'; // External CSS file

const NavBar = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [activeLink, setActiveLink] = useState('home'); // Set 'home' as default active

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    const handleLinkClick = (link) => {
        setActiveLink(link);
        setIsNavOpen(false); // Close the menu when a link is clicked
    };

    return (
        <motion.nav
            className="nav-bar"
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            transition={{ type: 'spring', stiffness: 100 }}
        >
            <div className="max-w-screen-xl flex items-center justify-between p-4 mx-auto">
                <div className="nav-logo text-white font-bold">Dr. Moksha Patel</div>

                {/* Hamburger Icon */}
                <button
                    type="button"
                    className="hamburger-btn md:hidden"
                    onClick={toggleNav}
                    aria-controls="navbar-menu"
                    aria-expanded={isNavOpen ? 'true' : 'false'}
                >
                    ☰
                </button>

                {/* Navigation Links */}
                <ul className={`nav-links ${isNavOpen ? 'show' : ''} md:flex md:w-auto`}>
                    {['home', 'about', 'services', 'contact'].map((link) => (
                        <li key={link}>
                            <a
                                href={`#${link}`}
                                onClick={() => handleLinkClick(link)}
                                className={`nav-item ${
                                    activeLink === link ? 'active-link' : ''
                                }`}
                            >
                                {link.charAt(0).toUpperCase() + link.slice(1)}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </motion.nav>
    );
};

export default NavBar;
