import React from 'react';
import { motion } from 'framer-motion';
import './herosection.css';

const HeroSection = () => {
    return (
        <section className="hero-section flex items-center justify-center relative">
            {/* Overlay for better text contrast */}
            <div className="overlay"></div>
            <motion.div
                className="hero-content text-center z-10"
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
            >
                <motion.h1
                    className="hero-heading"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3, duration: 1 }}
                >
                    Dr. Moksha Patel (PT)
                </motion.h1>
                <motion.h2
                    className="hero-subheading"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.6, duration: 1 }}
                >
                    Dedicated Physiotherapist - Providing Personalized Care for Orthopaedic, Neurological, Pediatrics, Geriatric, and Post-Operative Conditions
                </motion.h2>
                <motion.p
                    className="hero-description"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.9, duration: 1 }}
                >
                    Your health and mobility are my top priorities. Offering expert care to enhance your well-being and help you regain confidence in your movement.
                </motion.p>
                <motion.div
                    className="cta-buttons"
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 1.2, duration: 1 }}
                >
                    <a href="#about" className="cta-button">Learn More About Me</a>
                    <a href="#contact" className="cta-button">Contact Me</a>
                </motion.div>
            </motion.div>
        </section>
    );
};

export default HeroSection;
