import React from 'react';
import HeroSection from './components/herosection/herosection';
import AboutSection from './pages/about';
import ServicesPage from './pages/services';
import NavBar from './components/navbar/navbar';
import ContactPage from './pages/contact';
import './App.css'; // General styles

const App = () => {
  return (
    <div className="app-container">
      {/* Navigation Bar */}
      <NavBar />

      {/* Hero Section */}
      <section id="home" className="section">
        <HeroSection />
      </section>

      {/* About Section */}
      <section id="about" className="section">
        <AboutSection />
      </section>

      {/* Services Section */}
      <section id="services" className="section">
        <ServicesPage />
      </section>

      {/* Contact Section */}
      <section id="contact" className="section">
        <ContactPage />
      </section>
    </div>
  );
};

export default App;
