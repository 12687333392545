import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import './services.css'; // Import the updated CSS

const servicesData = [
  {
    title: 'Orthopaedic Conditions',
    description: 'We offer specialized treatments for musculoskeletal injuries, joint pain, and post-surgical recovery.',
    icon: '🦴',
    details: 'Our orthopaedic services focus on restoring joint health, reducing pain, and enhancing mobility through personalized exercise programs, manual therapy, and state-of-the-art equipment.',
  },
  {
    title: 'Neurological Conditions',
    description: 'Our care for neurological conditions helps patients regain strength and coordination.',
    icon: '🧠',
    details: 'We use evidence-based therapies to treat conditions like stroke, multiple sclerosis, and Parkinson’s disease. Our individualized therapy plans aim to improve motor function, balance, and overall quality of life for our patients.',
  },
  {
    title: 'Pediatrics Conditions',
    description: 'Specialized physiotherapy care designed for young patients with unique needs.',
    icon: '👶',
    details: 'Our pediatric care services include therapy for developmental delays, congenital disorders, and injuries. We create fun, engaging, and effective treatment plans tailored to each child’s needs, focusing on enhancing mobility, coordination, and independence.',
  },
  {
    title: 'Geriatric Conditions',
    description: 'Tailored physiotherapy services for seniors, focusing on mobility, balance, and overall health.',
    icon: '👵',
    details: 'Our geriatric care program helps older adults stay active, reduce the risk of falls, and improve their quality of life through balance training, strength-building exercises, and fall-prevention strategies.',
  },
  {
    title: 'Post-Operative Rehabilitation (Joint Replacements)',
    description: 'Comprehensive rehabilitation services for joint replacements and other post-surgical recovery.',
    icon: '🏥',
    details: 'Our post-operative rehabilitation services aim to accelerate recovery, reduce pain, and restore functionality after surgery. We offer tailored programs focusing on joint mobility, strength, and gradual reintroduction to daily activities.',
  },
];

const ServicesPage = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <div className="services-page bg-gray-100 py-8">
      {/* Main Services Header */}
      <motion.div
        className="text-center mb-12 mt-12"
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <h2 className="text-xl font-bold text-gray-800 mb-2">Services</h2>
        <p className="text-lg text-gray-600">
          Comprehensive care for a variety of conditions, tailored to each patient’s needs.
        </p>
      </motion.div>

      {/* Main Services Section */}
      <div className="max-w-4xl mx-auto">
        {servicesData.map((service, index) => (
          <motion.div
            key={index}
            className="mb-6 bg-white shadow-lg rounded-lg p-6 cursor-pointer"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            onClick={() => toggleAccordion(index)}
          >

<div className="text-lg mr-4 float-left">{service.icon}</div>
<h3 className="text-lg font-semibold">{service.title}</h3>
            <div className="flex items-center justify-between">
              
              <div className="flex items-center">
                
                <div className="flex-grow">
                  <p className="text-base text-gray-700">{service.description}</p>
                </div>
              </div>
              <motion.div
                initial={{ rotate: 0 }}
                animate={{ rotate: activeAccordion === index ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              >
                ▼
              </motion.div>
            </div>
            <AnimatePresence>
              {activeAccordion === index && (
                <motion.div
                  className="text-sm text-gray-600"
                  initial={{ y: -20, height: 0, opacity: 0 }}
                  animate={{ y: 0, height: 'auto', opacity: 1 }}
                  exit={{
                    opacity: 0,
                    height: 0,
                    transition: { opacity: { duration: 0.2 }, height: { duration: 0.6 } },
                  }}
                  transition={{ duration: 0.6, ease: 'easeInOut' }}
                >
                  <br />
                  {service.details}
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ServicesPage;
