import React, { useState } from 'react';
import './contact.css';  // Import the external CSS file
import { motion } from 'framer-motion';


const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission (e.g., sending data to the server)
    console.log('Form submitted:', formData);
    setFormData({
      name: '',
      email: '',
      message: ''
    });
  };

  return (
    <section className="contact-section">
        {/* Highlighted Home Visits Section */}
      
      <div className="contact-container">
      <div className="highlight-section mt-12 mb-12 bg-blue-100 p-6 rounded-lg shadow-xl">
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          <h2 className="text-3xl font-bold text-blue-800 mb-4">Home Visits within Vadodara</h2>
          <p className="text-blue-700">
            I provide personalized physiotherapy care directly at your home, ensuring maximum comfort and convenience for our patients within Vadodara. My home visits offer tailored treatment plans designed to meet individual needs, helping you recover in a familiar and supportive environment.
          </p>
        </motion.div>
      </div>
        <div className="contact-content">
            
          {/* Left Side: Home Visits Info */}
          <div className="left-section">
  <h1>Get in Touch with Dr. Moksha Patel (PT)</h1>

  <p>
    Have questions about physiotherapy or the services I offer? Feel free to reach out! I'm here to assist you in your journey to better health and mobility.
  </p>

  {/* Email and Phone Information */}
  <div className="contact-info mt-6">
    <p className="font-semibold text-lg">Contact Information</p>

    <p>
      <strong>Email:</strong>{' '}
      <a href="mailto:moxapatel1908@gmail.com" className="text-blue-200 hover:text-blue-400">
        moxapatel1908@gmail.com
      </a>
    </p>

    <p>
      <strong>Phone:</strong>{' '}
      <a href="tel:+916353937571" className="text-blue-200 hover:text-blue-400">
        +91 6353937571
      </a>
    </p>
  </div>

  {/* Home Visits Info (Commented Out for Now) */}
  {/* <div className="home-visits-info mt-6">
    <p className="font-semibold text-lg">Home Visits Available in Vadodara</p>
    <p>
      I understand that getting to a clinic can sometimes be difficult, which is why I offer home visits within Vadodara for your convenience. Whether you're recovering from surgery or need help with mobility challenges, I can come to your home and provide personalized physiotherapy care tailored to your needs.
    </p>
    <p>
      Contact me to schedule a home visit or learn more about how I can assist you in the comfort of your own home within Vadodara.
    </p>
  </div> */}
</div>


          {/* Right Side: Contact Form */}
          <div className="right-section">
            <div className="contact-form-container">
              <h1 className='text-white'>Contact Me</h1>
              {/* <p>
                If you would like to inquire about my services, schedule an appointment, or ask any other questions, please fill out the form below. I'll get back to you as soon as possible.
              </p> */}

              <form onSubmit={handleSubmit}>
                <div className="flex-1">
                  <label className="block mt-2 text-sm text-white">Full Name</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Your Full Name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex-1 mt-6">
                  <label className="block mt-2 text-sm text-white">Email address</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="someone@example.com"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>

                <div className="w-full mt-6">
                  <label className="block mt-2 text-sm text-white">Message</label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Your message here"
                  ></textarea>
                </div>

                <button type="submit">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
