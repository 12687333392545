import "./card.css";
import { motion } from "framer-motion";
import base from "../../assets/cardbase.png";
import hero from "../../assets/hero.webp";

const cardVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    rotate: -5,
    transition: {
      type: "spring",
      bounce: 0.5,
      duration: 1,
    },
  },
};

const splashVariants = {
  offscreen: {
    opacity: 0,
    scale: 0.8,
  },
  onscreen: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

function Card({ photoUrl, baseImage }) {
  return (
    <motion.div
      className="card-container"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
    >
      <motion.div className="splash-container" variants={splashVariants}>
        <img src={baseImage} alt="Background graphic" className="card-base-image" />
      </motion.div>
      <motion.div className="card" variants={cardVariants}>
        <img src={photoUrl} alt="Card content" className="card-photo" />
      </motion.div>
    </motion.div>
  );
}

const photos = [
  { photoUrl: hero, baseImage: base },
];

export default function Cards() {
  return (
    <div className="cards-wrapper">
      {photos.map(({ photoUrl, baseImage }, index) => (
        <Card photoUrl={photoUrl} baseImage={baseImage} key={index} />
      ))}
    </div>
  );
}
