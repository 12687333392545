import React from 'react';
import Card from '../components/cards/card'; // Import the Card component
import { motion } from 'framer-motion';
import './about.css'; // Optional CSS for additional styles
// import Typewriter from '../components/typewriter/typewriter';

const typewriterVariants = {
    offscreen: { opacity: 0, x: -100 },
    onscreen: {
        opacity: 1,
        x: 0,
        transition: { duration: 1.2, ease: "easeInOut" }
    }
};

const AboutSection = () => {
    return (

        <div className="">
            <h1 className="about-section-heading">About Me</h1>
            <div className="about-section-container">
                <div className="card-wrapper">
                    <Card /> {/* The Card component rendering the image or visual element */}
                </div>
                <div className="text-container">
                    <motion.h3
                        className="section-heading"
                        variants={typewriterVariants}
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true }}
                    >
                        Introduction
                    </motion.h3>
                    {/* <Typewriter texts={"Introduction"}/> */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, delay: 0.3 }}
                        viewport={{ once: true }}
                    >
                        <p className="section-content">
                            I am Dr. Moksha Patel, a dedicated physiotherapist with a passion for helping patients regain mobility, strength, and confidence in their daily lives. I completed my medical education at Drs. Kiran & Pallavi Patel Global University (KPGU), Vadodara
                            (Formerly, BITS Edu Campus). I chose physiotherapy due to my commitment to making a positive difference in the lives of my patients. My empathetic approach, combined with a dedication to personalized care, ensures each patient receives tailored treatment that addresses their unique needs.
                        </p>
                    </motion.div>
                    <motion.h3
                        className="section-heading"
                        variants={typewriterVariants}
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true }}
                    >
                    <h3 className="section-heading">Professional Statement</h3>
                    </motion.h3>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, delay: 0.6 }}
                        viewport={{ once: true }}
                    >

                        <p className="section-content">
                            I specialize in treating a wide range of conditions, including orthopaedic, neurological, paediatric, geriatric, and post-operative care. I focus on crafting personalized treatment plans that empower my patients to regain their health and mobility.
                        </p>
                    </motion.div>
                    <motion.h3
                        className="section-heading"
                        variants={typewriterVariants}
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true }}
                    >
                    <h3 className="section-heading">Certifications and Licenses</h3>
                    </motion.h3>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, delay: 0.9 }}
                        viewport={{ once: true }}
                    >

                        <p className="section-content">
                            <strong>GPC-21250</strong> - I am a licensed physiotherapist certified under Gujarat State Council for Physiotherapy. This certification is a testament to my adherence to professional standards and my commitment to providing top-notch patient care.
                        </p>
                    </motion.div>
                </div>
            </div>

        </div>
    );
};

export default AboutSection;
